import { useEffect, useState, useMemo, useCallback } from "react"
import debounce from "lodash.debounce"
import { ChevronDown } from "lucide-react"
import { addPropertyControls, ControlType } from "framer"

const HEADING_TO_DISPLAY = ["h2"]

export default function ScrollProgress(props) {
    const [isOpen, setIsOpen] = useState(false)
    const [progress, setProgress] = useState(0)
    const [headings, setHeadings] = useState([])
    const [activeHeading, setActiveHeading] = useState(null)
    const {
        position = "top-center",
        theme = "dark",
        description,
        defaultHeading,
    } = props

    const getPositionStyles = useMemo(
        () =>
            ({
                "top-left": {
                    top: "7%",
                    left: "5%",
                    transform: "translate(0, -7%)",
                },
                "top-center": {
                    top: "7%",
                    left: "50%",
                    transform: "translate(-50%, -7%)",
                },
                "top-right": {
                    top: "7%",
                    right: "5%",
                    transform: "translate(0, -7%)",
                },
                "bottom-left": {
                    bottom: "7%",
                    left: "5%",
                    transform: "translate(0, 7%)",
                },
                "bottom-center": {
                    bottom: "7%",
                    left: "50%",
                    transform: "translate(-50%, 7%)",
                },
                "bottom-right": {
                    bottom: "7%",
                    right: "5%",
                    transform: "translate(0, 7%)",
                },
            })[position],
        [position]
    )

    const getThemeColors = useMemo(
        () =>
            theme === "dark"
                ? {
                      bg: "#222",
                      textPrimary: "white",
                      textSecondary: "rgb(166, 165, 165)",
                  }
                : {
                      bg: "white",
                      textPrimary: "#222",
                      textSecondary: "rgb(100, 100, 100)",
                  },
        [theme]
    )

    useEffect(() => {
        const result = []
        const children = description?.props?.children || []
        children
            .filter((item) => item && HEADING_TO_DISPLAY.includes(item.type))
            .forEach((item, index) => {
                if (item?.props) {
                    const children = item.props.children
                    const { formattedId, formattedHeading } = formatHeading(
                        children,
                        index
                    )
                    result.push({
                        id: formattedId,
                        heading: formattedHeading,
                        type: item.type,
                        originalIndex: index,
                    })
                }
            })
        setHeadings(result)

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveHeading(entry.target.id)
                }
            })
        })

        const updateObservers = debounce(() => {
            const headingElements = document.querySelectorAll("h2")
            observer.disconnect()
            headingElements.forEach((heading) => observer.observe(heading))
        }, 200)

        const mutationObserver = new MutationObserver(updateObservers)
        mutationObserver.observe(document.body, {
            childList: true,
            subtree: true,
        })

        updateObservers()

        return () => {
            observer.disconnect()
            mutationObserver.disconnect()
        }
    }, [description])

    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.body.scrollHeight - window.innerHeight
            const scrolled = window.scrollY
            const calculated = Math.min(
                Math.round((scrolled / totalHeight) * 100),
                100
            )
            setProgress(calculated)
        }

        const debouncedScroll = debounce(handleScroll, 100)
        window.addEventListener("scroll", debouncedScroll)
        return () => window.removeEventListener("scroll", debouncedScroll)
    }, [])

    return (
        <>
            <style>{`
                .progress-container { position: fixed; z-index: 10; display: flex; flex-direction: column; align-items: center; background-color: ${getThemeColors.bg}; border-radius: 1.5rem; box-shadow: 0 5px 2rem rgba(0, 0, 0, 0.2); transition: all 350ms cubic-bezier(0.01, 0.71, 0.06, 1.23); pointer-events: none; ${Object.entries(
                    getPositionStyles
                )
                    .map(([key, value]) => `${key}: ${value};`)
                    .join("\n")} }
                .progress-container.closed { height: 50px; width: 340px; justify-content: center; }
                .progress-container.open { width: 340px;  min-height: 80px; padding-bottom: 20px; max-height: 80vh; }
                .progress-header { position: relative; width: 100%; height: 50px; display: flex; align-items: center; pointer-events: auto; }
                .progress-title { position: absolute; left: 16px; height: 100%; display: flex; align-items: center; }
                .progress-circle-container { position: relative; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; }
                .progress-circle-bg { position: absolute; inset: 0; border-radius: 50%; background-color: #a09b9b; }
                .progress-circle-progress { position: absolute; inset: 0; border-radius: 50%; background: conic-gradient(${getThemeColors.textPrimary} var(--t), transparent 0turn); }
                .progress-circle-center { position: relative; width: 22px; height: 22px; background-color: ${getThemeColors.bg}; border-radius: 50%; }
                .progress-label { color: ${getThemeColors.textPrimary}; font-size: 14px; margin: 0 4px 0 8px; width: 170px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
                .chevron { transition: transform 200ms ease; }
                .chevron.open { transform: rotate(180deg); }
                .progress-percent { position: absolute; right: 16px; display: flex; align-items: center; justify-content: center; height: 30px; width: 60px; background-color: ${theme === "dark" ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.1)"}; border-radius: 1rem; color: ${getThemeColors.textPrimary}; font-weight: bold; font-size: 0.89rem; }
                .progress-links { display: none; width: 90%; flex-direction: column; opacity: 0; transition: opacity 200ms ease; margin-top: 16px; margin-bottom: 8px; overflow-y: auto; max-height: calc(80vh - 80px); pointer-events: auto; }
                .progress-container.open .progress-links { display: flex; opacity: 1; }
                .progress-link { color: ${getThemeColors.textSecondary}; text-decoration: none; cursor: pointer; transition: color 200ms ease; padding: 4px 0; font-size: 14px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
                .progress-link:hover { color: ${getThemeColors.textPrimary}; }
                .overlay { position: fixed; inset: 0; z-index: 5; background-color: rgba(0, 0, 0, 0.1); backdrop-filter: blur(0.6rem); transition: all 200ms ease; pointer-events: auto; }
            `}</style>

            <div className={`progress-container ${isOpen ? "open" : "closed"}`}>
                <div
                    className="progress-header"
                    onClick={() => setIsOpen((prev) => !prev)}
                >
                    <div className="progress-title">
                        <div className="progress-circle-container">
                            <div className="progress-circle-bg" />
                            <div
                                className="progress-circle-progress"
                                style={{ "--t": `${progress}%` }}
                            />
                            <div className="progress-circle-center" />
                        </div>
                        <span className="progress-label">
                            {headings.find(
                                (heading) => heading.id === activeHeading
                            )?.heading ?? defaultHeading}
                        </span>
                        <div className={`chevron ${isOpen ? "open" : ""}`}>
                            <ChevronDown size={18} />
                        </div>
                    </div>
                    <span className="progress-percent">{progress}%</span>
                </div>

                <div className="progress-links">
                    {headings
                        .sort((a, b) => a.originalIndex - b.originalIndex)
                        .map(({ id, heading }) => (
                            <a
                                className="progress-link"
                                href={`#${id}`}
                                onClick={() => setIsOpen(false)}
                                key={id}
                            >
                                {heading}
                            </a>
                        ))}
                </div>
            </div>

            {isOpen && (
                <div className="overlay" onClick={() => setIsOpen(false)} />
            )}
        </>
    )
}

addPropertyControls(ScrollProgress, {
    position: {
        title: "Position",
        type: ControlType.Enum,
        options: [
            "top-left",
            "top-center",
            "top-right",
            "bottom-left",
            "bottom-center",
            "bottom-right",
        ],
        defaultValue: "top-center",
    },
    defaultHeading: {
        title: "CompHeading",
        defaultValue: "Inhaltsverzeichnis",
        type: ControlType.String,
    },
    theme: {
        title: "Theme",
        type: ControlType.Enum,
        options: ["dark", "light"],
        defaultValue: "dark",
    },
    description: {
        title: "Content",
        type: ControlType.RichText,
    },
})

const formatHeading = (children, index) => {
    let formattedHeading = typeof children === "string" ? children : ""
    let formattedId = formattedHeading
        .trim()
        .toLowerCase()
        .replace(/[^a-zäöü]+/g, "-")
        .replace(/^-+|-+$/g, "")

    return { formattedId, formattedHeading }
}
